@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Colors */
    --color-primary: 73 43 209;
    --color-secondary: 194 74 249;
    --color-tertiary: "";
    --color-white: 255 255 255;
    --color-black: 0 0 0;
    --color-dark: 20 20 20;
    --color-dark-light: 40 40 40;
    /* Font size */
    --fs-heading1: 48px;
    --fs-heading2: 40px;
    --fs-heading4: 20px;
    --fs-heading5: 18px;
    --fs-body-large: 18px;
    --fs-body: 16px;
    --fs-body-sm: 14px;
    --fs-body-xs: 14px;
    /* Line height */
    --lh-heading1: 48px;
    --lh-heading2: 48px;
    --lh-heading4: 32px;
    --lh-heading5: 28px;
    --lh-body-large: 28px;
    --lh-body: 24px;
    --lh-body-sm: 16px;
    --lh-body-xs: 14px;
  }
  html {
    @apply scroll-smooth;
  }
  body {
    @apply bg-black;
  }
}

.hero-section {
  height: 100%;
  min-height: calc(100vh - 72px);
}
